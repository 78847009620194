import "animate.css";
import styled from "styled-components";
//import image1 from "../../assets/Images/bg-welcome.png";

export const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 5rem 0;
`;

export const Container = styled.div`
  width: 75%;
  margin: 0 auto;
 

  

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 70em) {
    width: 85%;
  }

  @media screen and (max-width: 64em) {
    width: 100%;
    flex-direction: column;

    & > *:last-child {
      width: 80%;
    }
  }

  @media screen and (max-width: 40em) {
    width: 100%;
    flex-direction: column;

    & > *:last-child {
      width: 90%;
    }
  }
`;


export const Box = styled.div`
  width: 100%;
  height: 100%;
  min-height: 60vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 64em) {
    min-height: 50vh;
    margin-top: 1rem;
  }
  button {
    margin-top:6rem;
    margin-left: 8rem;
  }
`;

export const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};

  color: #0026ff;
  align-self: flex-start;
  width: 80%;
  margin: 0 auto;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }

  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontlg};
  }
`;

export const SubText = styled.p`
  font-size: 18px;
  color: #000000;
  align-self: flex-start;
  width: 80%;
  margin: 2rem auto;
  font-weight: 400;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
  }
`;

export const SubTextLight = styled.p`
  font-size: 16px;
  color: ${(props) => `#000000, 0.9)`};
  align-self: flex-start;
  width: 80%;
  margin: 1rem auto;
  font-weight: 400;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontsm};
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontsm};
  }

  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontxs};
  }
`;
