import styled from "styled-components";

export const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Container = styled.div`
  width: 75%;
  margin: 0 auto;
  /* background-color: lightblue; */

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 100em) {
    width: 85%;
  }

  @media screen and (max-width: 64em) {
    width: 85%;
    flex-direction: column;

    & > *:last-child {
      width: 85%;
    }
  }

  @media screen and (max-width: 40em) {
    width: 100%;
    flex-direction: column;

    & > *:last-child {
      width: 90%;
    }
  }
`;

export const Box = styled.div`
  width: 100%;
  height: 100%;
  min-height: 50vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 64em) {
    min-height: 50vh;
    margin-top: 1rem;
  }
`;

export const Title = styled.h2`
  font-size: 40px;

  color: #4c00ff;
  align-self: flex-start;
  width: 80%;
  margin: 0 auto;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontxl};
  }

  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontlg};
  }
`;

export const SubText = styled.p`
  font-size: 20px;
  color: gray;
  align-self: flex-start;
  width: 80%;
  margin: 1rem auto;
  font-weight: 300;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size:  14px;
  }

  @media (max-width: 40em) {
    font-size: $ 14px;
  }

  @media (max-width: 30em) {
    font-size:  14px;
  }
`;

export const SubTextLight = styled.p`
  font-size:  20px;
  color: gray;
  align-self: flex-start;
  width: 80%;
  margin: 1rem auto;
  font-weight: 400;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontsm};
  }

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontsm};
  }

  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontxs};
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  align-self: flex-start;

  @media screen and (max-width: 48em) {
    align-self: center;
    text-align: center;

    button {
      margin: 0 auto;
    }
  }
`;
