import { FormProvider, useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import * as zod from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import "../../styles/global.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Envelope } from "phosphor-react"


import emailjs from 'emailjs-com';



import styles from "./FaleConosco.module.css"
// import { Link } from 'react-router-dom';

export function FaleConosco() {

  const toastifySuccess = () => {
    toast('Mensagem enviada...', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: 'submit-feedback success',
      toastId: 'notifyToast',
      style: {
        color: "#4c00ff",
        borderRadius: "6px",
        width: "200px",
        marginLeft: "75px",
        backgroundColor: "#ffffff"

      }
    });
  };
  const faleConoscoValidationSchema = zod.object({
    name: zod.string().min(3, "É necessário informar seu Nome."),
    email: zod.string().email("É necessário informar seu E-mail."),
    subject: zod.string().min(3, "É necessário informar o assunto."),
    message: zod.string().min(5, "Favor preencher o campo mensagem."),
  })

  type FaleConoscoProps = zod.infer<typeof faleConoscoValidationSchema>

  const faleConoscoForm = useForm<FaleConoscoProps>({
    resolver: zodResolver(faleConoscoValidationSchema),
    defaultValues: {
      name: '',
      email: '',
      subject: '',
      message: '',
    }
  })

  const { handleSubmit, register, reset, formState: { errors } } = faleConoscoForm

  const onSubmit = async (data: FaleConoscoProps) => {
    const uId = process.env.REACT_APP_SERVICE_ID!
    const tempId = process.env.REACT_APP_TEMPLATE_ID!
    const pKey = process.env.REACT_APP_USER_ID!

    emailjs.send(
      uId,
      tempId,
      data,
      pKey)
      .then(() => {
        toastifySuccess();
        reset();
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <section id="fale-conosco" className='min-h-[75vh] flex items-center'>
      <div className='sm:flex-1 md:flex text-green-800'>
        <div className={styles.faleConoscoBox}>
          <div className='flex h-[45rem] w-[100%] justify-end items-center'>
            <div className="flex w-[95%] lg:w-[60%] h-[57%] bg-[#4c00ff] text-[#ffffff] rounded-[30px] items-center justify-center">
              <div className='flex'>
                <div className='flex items-center'>
                  <Envelope size={80} weight="fill" />
                </div>
                <div className='flex-1 px-4'>
                  <h4>Acompanhe nossas novidades</h4>
                  <p className='p-2'>Inscreva-se para receber atualizações</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.formBox}>
          <div>
            <h1 className='text-center text-[2.5rem]'>Entre em Contato</h1>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <FormProvider {...faleConoscoForm}>
                <div className='container'>
                  <div className='flex '>
                    <div className='col-6 p-2'>
                      <input
                        className={styles.formInput}
                        type='text'
                        {...register('name')}
                        placeholder='Nome'
                      />
                      <div className={styles.errorMessage}>
                        {errors.name && <span className='errorMessage'>{errors.name.message}</span>}
                      </div>
                    </div>
                    <div className='col-6 p-2'>
                      <input
                        className={styles.formInput}
                        type='email'
                        {...register('email')}
                        placeholder='E-mail'
                      />
                      <div className={styles.errorMessage}>
                        {errors.email && <span className='errorMessage'>{errors.email.message}</span>}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='col-12 p-2'>
                      <input
                        className={styles.formInput}
                        type='text'
                        {...register('subject')}
                        placeholder='Assunto'
                      />
                      <div className={styles.errorMessage}>
                        {errors.subject && <span className='errorMessage'>{errors.subject.message}</span>}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className='col-12 p-2'>
                      <textarea
                        className={styles.formInput}
                        style={{ height: "8rem" }}
                        rows={3}
                        {...register('message')}
                        placeholder='Mensagem'
                      />
                      <div className={styles.errorMessage}>
                        {errors.message && <span className='errorMessage'>{errors.message.message}</span>}
                      </div>
                    </div>
                  </div>
                  <div className='flex items-center justify-center'>
                    <button className={styles['submit-btn']} type='submit'>
                      Enviar
                    </button>
                  </div>
                </div>
              </FormProvider>
            </form>
          </div>
        </div>
        <ToastContainer />
      </div>
    </section>
  );
}

