import styled from "styled-components";

export const Section = styled.section`
  min-height: ;
  width: 100vw;
  background-color: #4c00ff;
  color: white;

  position: flex;
  bottom:1px;
  display: flex;
  /* justify-content: center;
  align-items: center; */
  flex-direction: column;
`;

export const Container = styled.div`
  width: 87%;
  /* margin-top: 10rem; */
  background-color: none;
  margin: 0 auto;
  display: fixed;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  
`;

export const Left = styled.div`
  display: flex;
  margin: 2rem auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 0em) {
    width: 87%%;
    /* background-color: red; */
    margin: 0 auto;
  }
`;

export const IconList = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  

  & > * {
    padding-right: 0.5rem;
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.2);
    }
  }
`;

export const MenuItems = styled.ul`
  list-style: none;
  width: 50%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 1rem;
  font-size:  12px;
  
`;

export const MenuItem = styled.li`
  width: fit-content;
  cursor: pointer;
  position: relative;
  

  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 0%;
    height: 2px;
    background-color: ${(props) => props.theme.text};
    transition: width 0.3s ease;
  }
  &:hover::after {
    width: 100%;
  }

  @media screen and (max-width: 64em) {
    display: none;
  }
`;

export const Bottom = styled.div`
  width: 100%;
  /* margin: 0 auto; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  text-align: center;
  margin-bottom:1rem;

 
`;
