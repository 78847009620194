//import { ButtonWhite } from "../../components/button-white";
import {
  Container,
  Box,
  Title,
  SubText,
  SubTextLight,
  //ButtonContainer,
  Section,
} from "./styles";

import img1 from "../../assets/images/smart.png";

export function Purpose() {
  return (
    <Section id="purpose">
      <Container>
        <Box>
          <Title>PROPÓSITO</Title>
          <SubText>
          Suportar a transição energética global com soluções inteligentes e eficientes. 
          Nosso empenho é em ser a força motriz por trás da inovação disruptiva no campo da eficiência energética, 
          alavancando o poder da tecnologia e do conhecimento especializado até 2030.
            
          </SubText>
          <Title>MISSÃO</Title>
          <SubTextLight>
            Fornecer soluções inovadoras e personalizadas que atendem às necessidades específicas da indústria. 
            <br />
            <br />
            #SmartServiceMovement
            <br />
            <br />
          </SubTextLight>
        </Box >
        <img src={img1} alt="nft"  />
      </Container>
    </Section>
  );
}
