import { CaretDown, MouseSimple } from 'phosphor-react';
import React from 'react'
import { scrollTo } from '../../utils/scroll-to';

type MouseProps = {
  route?: string
}
export function MouseDown({ route = '#' }: MouseProps) {
  return (
    <div
      className="flex justify-center items-end w-[100vw] h-[60%] absolute left-0 bottom-3 ">
      <div
        onClick={() => scrollTo(route)}
        className='flex flex-col justify-center items-center cursor-pointer'>
        <MouseSimple size={40} color="#ffffff" weight="bold" />
        <div className='animate-bounce  mt-[1rem] '>
          <CaretDown size={30} color="#ffffff" weight="bold" />
        </div>
      </div>
      {/* <svg
        width="75"
        height="auto"
        viewBox="0 0 107 124"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="grp">
          <path
            className="animate-bounce"
            d="M54.0084 123.537L59.5972 118.015C60.1401 117.447 60.1401 116.532 59.5653 115.996C58.9904 115.46 58.0962 115.46 57.5533 115.996L54.4555 119.056L54.4555 97.42C54.4555 96.6313 53.7849 96.0002 52.9865 96.0002C52.1881 96.0002 51.5494 96.6313 51.5494 97.42L51.5494 119.056L48.4516 115.996C47.8767 115.46 46.9506 115.46 46.4077 116.028C45.8648 116.595 45.8648 117.479 46.4077 118.015L51.9965 123.537C52.5394 124.105 53.4336 124.168 54.0084 123.631C54.0084 123.6 54.0404 123.6 54.0084 123.537Z"
            fill="#00ff00"
          />
          <g id="text">
            <path
              d="M4.90977 64.6349C4.40577 64.6349 3.99177 64.7669 3.66777 65.0309C3.35577 65.2829 3.19978 65.6369 3.19978 66.0929C3.19978 66.5489 3.31377 66.9089 3.54177 67.1729C3.78177 67.4249 4.09378 67.6229 4.47778 67.7669C4.86178 67.8989 5.28177 68.0189 5.73777 68.1269C6.19377 68.2349 6.64977 68.3669 7.10577 68.5229C7.56177 68.6789 7.98178 68.8709 8.36578 69.0989C8.74978 69.3269 9.05578 69.6629 9.28378 70.1069C9.52378 70.5389 9.64378 71.0669 9.64378 71.6909C9.64378 72.7229 9.22978 73.6109 8.40178 74.3549C7.58578 75.0989 6.49377 75.4709 5.12577 75.4709C3.76977 75.4709 2.67178 75.1529 1.83178 74.5169C0.991776 73.8689 0.571777 72.9629 0.571777 71.7989H3.27177C3.36777 72.9029 3.99778 73.4549 5.16178 73.4549C5.73778 73.4549 6.19378 73.3049 6.52978 73.0049C6.86578 72.7049 7.03378 72.3089 7.03378 71.8169C7.03378 71.3129 6.81178 70.9169 6.36777 70.6289C5.92377 70.3409 5.38378 70.1249 4.74778 69.9809C4.12378 69.8369 3.49378 69.6629 2.85778 69.4589C2.22178 69.2549 1.68177 68.9009 1.23777 68.3969C0.793774 67.8809 0.571777 67.2029 0.571777 66.3629C0.571777 65.2229 0.985774 64.3109 1.81377 63.6269C2.64177 62.9429 3.70377 62.6009 4.99977 62.6009C6.30778 62.6009 7.35778 62.9009 8.14978 63.5009C8.94178 64.0889 9.37378 64.9889 9.44578 66.2009H6.67377C6.62577 65.7209 6.44578 65.3429 6.13378 65.0669C5.82178 64.7789 5.41377 64.6349 4.90977 64.6349Z"
              fill="#00ff00"
            />
            <path
              d="M12.7402 74.0849C11.8282 73.1489 11.3722 71.9069 11.3722 70.3589C11.3722 68.8109 11.8282 67.5689 12.7402 66.6329C13.6522 65.6969 14.8342 65.2289 16.2862 65.2289C17.4982 65.2289 18.5122 65.5229 19.3282 66.1109C20.1442 66.6989 20.6902 67.5329 20.9662 68.6129H18.2302C17.9062 67.7849 17.2582 67.3709 16.2862 67.3709C15.5542 67.3709 14.9782 67.6409 14.5582 68.1809C14.1502 68.7089 13.9462 69.4349 13.9462 70.3589C13.9462 71.2829 14.1502 72.0149 14.5582 72.5549C14.9782 73.0829 15.5542 73.3469 16.2862 73.3469C17.2462 73.3469 17.8942 72.9269 18.2302 72.0869H20.9662C20.6782 73.1309 20.1202 73.9589 19.2922 74.5709C18.4762 75.1829 17.4742 75.4889 16.2862 75.4889C14.8342 75.4889 13.6522 75.0209 12.7402 74.0849Z"
              fill="#00ff00"
            />
            <path
              d="M25.4052 65.3729V67.1369C26.0772 65.8649 27.0492 65.2289 28.3212 65.2289V67.8209H27.6912C26.9352 67.8209 26.3652 68.0129 25.9812 68.3969C25.5972 68.7689 25.4052 69.4169 25.4052 70.3409V75.3449H22.8672V65.3729H25.4052Z"
              fill="#00ff00"
            />
            <path
              d="M34.5549 73.3469C35.2629 73.3469 35.8689 73.0889 36.3729 72.5729C36.8889 72.0449 37.1469 71.3069 37.1469 70.3589C37.1469 69.3989 36.8949 68.6609 36.3909 68.1449C35.8869 67.6289 35.2749 67.3709 34.5549 67.3709C33.8469 67.3709 33.2469 67.6289 32.7549 68.1449C32.2629 68.6609 32.0169 69.3989 32.0169 70.3589C32.0169 71.3189 32.2629 72.0569 32.7549 72.5729C33.2469 73.0889 33.8469 73.3469 34.5549 73.3469ZM30.9009 74.0669C29.9289 73.1189 29.4429 71.8769 29.4429 70.3409C29.4429 68.8049 29.9349 67.5689 30.9189 66.6329C31.9029 65.6969 33.1209 65.2289 34.5729 65.2289C36.0369 65.2289 37.2609 65.6969 38.2449 66.6329C39.2289 67.5689 39.7209 68.8049 39.7209 70.3409C39.7209 71.8769 39.2229 73.1189 38.2269 74.0669C37.2429 75.0149 36.0189 75.4889 34.5549 75.4889C33.0909 75.4889 31.8729 75.0149 30.9009 74.0669Z"
              fill="#00ff00"
            />
            <path
              d="M41.7285 75.3449V62.0249H44.2665V75.3449H41.7285Z"
              fill="#00ff00"
            />
            <path
              d="M46.9668 75.3449V62.0249H49.5048V75.3449H46.9668Z"
              fill="#00ff00"
            />
            <path
              d="M63.9555 72.5189C64.4835 71.9909 64.7475 71.2709 64.7475 70.3589C64.7475 69.4469 64.4835 68.7329 63.9555 68.2169C63.4275 67.6889 62.8095 67.4249 62.1015 67.4249C61.3935 67.4249 60.7755 67.6829 60.2475 68.1989C59.7315 68.7149 59.4735 69.4289 59.4735 70.3409C59.4735 71.2529 59.7375 71.9729 60.2655 72.5009C60.7935 73.0289 61.4055 73.2929 62.1015 73.2929C62.8095 73.2929 63.4275 73.0349 63.9555 72.5189ZM58.2315 74.0489C57.3435 73.0769 56.8995 71.8409 56.8995 70.3409C56.8995 68.8409 57.3435 67.6169 58.2315 66.6689C59.1195 65.7089 60.2475 65.2289 61.6155 65.2289C62.9835 65.2289 64.0275 65.7929 64.7475 66.9209V62.0249H67.2675V75.3449H64.7475V73.6709C63.9915 74.8829 62.9415 75.4889 61.5975 75.4889C60.2535 75.4889 59.1315 75.0089 58.2315 74.0489Z"
              fill="#00ff00"
            />
            <path
              d="M74.387 73.3469C75.095 73.3469 75.701 73.0889 76.205 72.5729C76.721 72.0449 76.979 71.3069 76.979 70.3589C76.979 69.3989 76.727 68.6609 76.223 68.1449C75.719 67.6289 75.107 67.3709 74.387 67.3709C73.679 67.3709 73.079 67.6289 72.587 68.1449C72.095 68.6609 71.849 69.3989 71.849 70.3589C71.849 71.3189 72.095 72.0569 72.587 72.5729C73.079 73.0889 73.679 73.3469 74.387 73.3469ZM70.733 74.0669C69.761 73.1189 69.275 71.8769 69.275 70.3409C69.275 68.8049 69.767 67.5689 70.751 66.6329C71.735 65.6969 72.953 65.2289 74.405 65.2289C75.869 65.2289 77.093 65.6969 78.077 66.6329C79.061 67.5689 79.553 68.8049 79.553 70.3409C79.553 71.8769 79.055 73.1189 78.059 74.0669C77.075 75.0149 75.851 75.4889 74.387 75.4889C72.923 75.4889 71.705 75.0149 70.733 74.0669Z"
              fill="#00ff00"
            />
            <path
              d="M92.8645 65.3729H95.2945L92.2345 75.3449H89.5345L87.8245 68.6489L86.0965 75.3449H83.3965L80.3365 65.3729H82.8925L84.7285 72.9509L86.5645 65.3729H89.2285L91.0285 72.9329L92.8645 65.3729Z"
              fill="#00ff00"
            />
            <path
              d="M99.286 65.3729V66.8669C99.922 65.7749 100.954 65.2289 102.382 65.2289C103.522 65.2289 104.452 65.6069 105.172 66.3629C105.892 67.1069 106.252 68.1449 106.252 69.4769V75.3449H103.732V69.8369C103.732 69.0569 103.534 68.4629 103.138 68.0549C102.742 67.6349 102.202 67.4249 101.518 67.4249C100.834 67.4249 100.288 67.6349 99.88 68.0549C99.484 68.4629 99.286 69.0569 99.286 69.8369V75.3449H96.748V65.3729H99.286Z"
              fill="#00ff00"
            />
          </g>
          <g id="mouse">
            <path
              d="M52.9057 42.8276C45.0196 42.8276 38.6299 36.197 38.6299 28.0652V14.7207C38.6299 6.58886 45.0196 0 52.9057 0C60.7918 0 67.1816 6.58886 67.1816 14.7207V28.0652C67.1816 36.197 60.7918 42.8276 52.9057 42.8276ZM52.9057 3.54464C46.9204 3.54464 42.0674 8.54884 42.0674 14.7207V28.0652C42.0674 34.2371 46.9204 39.2412 52.9057 39.2412C58.8911 39.2412 63.7441 34.2371 63.7441 28.0652V14.7207C63.7441 8.54884 58.8911 3.54464 52.9057 3.54464Z"
              fill="#00ff00"
            />
            <path
              d="M52.9057 17.8448C51.6331 17.8448 50.5264 16.9175 50.5264 15.7462V10.4263C50.5264 9.30371 51.5778 8.32759 52.9057 8.32759C54.2337 8.32759 55.2851 9.25491 55.2851 10.4263V15.7462C55.2851 16.9175 54.2337 17.8448 52.9057 17.8448Z"
              fill="#00ff00"
            />
          </g>
        </g>
      </svg> */}
    </div>
  );
};


