import Typewriter from "typewriter-effect";
import "./TypeWritter.module.css";

export function TypeWritter() {
  return (
    <div className="typewriter-container">
      <div className="typewriter-text">
        <Typewriter
          options={{
            autoStart: true,
            loop: true,
          }}
          onInit={(typewriter) => {
            typewriter
              .typeString(`<span class="text__1">TECNOLOGIA E INOVAÇÃO</span>`)
              .pauseFor(1500)
              .deleteAll()
              .typeString(`<span class="text__2">EFICIÊNCIA ENERGÉTICA</span>`)
              .pauseFor(1500)
              .deleteAll()
              .typeString(`<span class="text__3">ENERGIAS RENOVÁVEIS</span>`)
              .pauseFor(1500)
              .deleteAll()
              .start();
          }}
        />
      </div>
    </div>
  );
}
