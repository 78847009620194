import { useEffect, useRef, useState } from 'react';
// @ts-ignore
import HALO from 'vanta/dist/vanta.halo.min.js'
import "./Halo.Module.css";
import { MouseDown } from '../mouse-button';
import { TypeWritter } from '../type-writter';

export function Halo() {
  const [vantaEffect, setVantaEffect] = useState(null)
  const myRef = useRef(null)
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(HALO({
        el: myRef.current,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.00,
        height: '80vh',
        minWidth: 200.00,
        with: '100vw',
        baseColor: '#4c00ff',
        backgroundColor: 0x0,
        xOffset: 0.20,
        
       
      }))
    }
    return () => {
      // @ts-ignore
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect])

  return (
    <>
      <section id="home" ref={myRef} className='vanta'>
        <div className="flex flex-col vantaAjust">
          {/* Text */}
          <div className="pl-8 pt-14 mt-8">
            <h1
              id="title"
              className="font-extrabold text-4xl sm:text-6xl md:text-8xl text-[#4c00ff] whitespace-nowrap slide-up"
            >
              SMART{" "}
              <span className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#4c00ff] to-[#ffffff]">
                <br />
                SERVICE
              </span>
              <span className="max-w-1/2 text-3xl font-bold text-[#ffffff] md:w-[200px] mt-4 pr-4 slide-up">
                <TypeWritter />
              </span>
            </h1>
          </div>
        </div>

        <MouseDown route='about' />
      </section >
    </>
  )
}


