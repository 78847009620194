import { LinkedinLogo } from "phosphor-react";
import { redirectToLink } from "../../utils/redirect-to-link";

import {
  Container,
  Name,
  Img,
  Box,
  Title,
  SubText,
  Section,
} from "./styles";

import img1 from "../../assets/images/founder.jpeg";

export function Founder() {
  return (
    <Section id="purpose">
      <Container>
      <Img>
      <img src={img1} alt="nft"  />
      </Img>
        <Box>
          <Title>FOUNDER
          </Title>
          <SubText>
          Entusiasta, empreendedor, investidor e mentor, um engenheiro em eterno aprendizado.
          Casado e pai de dois lindos filhos, Jhonathan Santos é especialista em digitalização 
          e transformação digital, apaixonado por conectar pessoas a projetos e impulsionado pela inovação acelerada.
          </SubText>
          <Name>Jhonathan Santos</Name>
          <Name> 
            <button onClick={() => redirectToLink("https://br.linkedin.com/in/jhonathan-santos/pt")}>
              <LinkedinLogo align-self="center" size={70} weight="fill" color="#4c00ff" />
          </button>
          
          </Name>
        </Box >
        
      </Container>
    </Section>
  );
}
