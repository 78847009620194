//import { ButtonWhite } from "../../components/button-white";

import {
  Box,
  Container,
  SubText,
  SubTextLight,
  Title,
  Section,
} from "./styles";

export function About() {
  return (
    <Section id="about">
      <Container>
        <Box>
          <Title>
            Bem-vindo a<br /> Smart Service
          </Title>
          <SubText>
            Somos uma STARTUP impulsionada pela aspiração de engendrar um futuro sustentável, 
            mediado por avanços em eficiência energética e inovações disruptivas. Constituída por uma coalizão 
            de especialistas em energia e tecnólogos, nossa entidade se dedica a reformular o paradigma energético 
            global por meio da implementação de soluções sustentáveis e tecnologicamente avançadas. Estamos engajados 
            na edificação de um legado pautado na maximização da eficiência energética, inovação e na promoção incisiva da sustentabilidade.
            <br />
            <br />
            Adotamos uma abordagem holística e altamente personalizada. O ponto de partida de nossa metodologia consiste em uma análise 
            rigorosa das demandas energéticas, empregando algoritmos avançados e ferramentas de diagnóstico energético para mapear pontos 
            críticos de ineficiência. Posteriormente, engajamos na engenharia de soluções customizadas, alinhadas as mais recentes inovações 
            em eficiência energética com as especificidades e desafios únicos apresentados por cada projeto. Este processo de desenvolvimento 
            é complementado por uma implementação sistemática e um suporte contínuo, assegurando a otimização e a sustentabilidade a longo 
            prazo das soluções propostas.
            <br />
          </SubText>
          <SubTextLight>
            “Entendemos a importância da prosperidade econômica independentemente da classe, e por outro lado, a certeza 
            de que, se não forem implementadas iniciativas à curto prazo em alguns anos, a crescente demanda por energia, poderá causar
            impactos significativos. É hora de agir!” 
          
            <br />
            <br />
            Jhonathan Santos – Founder da SMART SERVICE
          </SubTextLight>
          <div className="flex w-[100%] ml-[-2rem] md:ml-0">
          </div>
        </Box>
      </Container>
    </Section >
  );
}
